import axios from 'axios';

const sendEvent = (event, sessionId) => {
  const cfg = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: false
  };
  let service = axios.create(cfg);
  return new Promise((resolve) => {
    service.post('/consent/stats', {
      'event': event,
      's': sessionId
    })
    .catch(() => {})
    .finally(() => {
      resolve();
    });
  });
}

export default {
  sendEvent
};
