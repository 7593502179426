import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import loadable from '@loadable/component';

// Store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Service
import Datalayer from '../service/dataLayer.js';
import Tracking from '../service/tracking.js';

// Settings
import ConsentData from '../config/consent.js';

const mapStoreToProps = (state) => {
  return {
    store$config: state.config,
    store$consent: state.consent,
    store$lastRequest: state.lastRequest,
    store$sessionId: state.s
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    dispatch$setSessionId: (payload) => ({
      type: 'SET_SESSIONID',
      payload: payload
    }),
    dispatch$clearStore: (payload) => ({
      type: 'CLEAR_STORE',
      payload: payload
    }),
    dispatch$setConsent: (payload) => ({
      type: 'SET_CONSENT',
      payload: payload
    }),
    dispatch$setLastRequest: (payload) => ({
      type: 'SET_LASTREQUEST',
      payload: payload
    })
  }, dispatch)
};

class ConsentView extends React.Component {
  constructor(props) {
    super(props);

    this.ConsentConfig = window.WebConsent || {};
    this.state = {
      sessionId: uuidv4(),
      overlayActive: false,
      expanded: false
    };

    this.initGlobalFunction();

    this.customizeCallback = this.customizeCallback.bind(this);
    this.pushConsent = this.pushConsent.bind(this);
  }

  checkStatus() {
    const consentAll = ConsentData.sections.length === this.props.store$consent.length;
    const requestDelay = consentAll ? 180 : 14;
    let doConsentRequest = true;
    if (this.props.store$lastRequest !== null)  {
      let nextRequestDate = new Date();
      nextRequestDate.setTime(this.props.store$lastRequest);
      nextRequestDate.setDate(nextRequestDate.getDate() + requestDelay);
      if ((new Date()).getTime() < nextRequestDate.getTime()) {
        doConsentRequest = false;
      }
    }
    if (doConsentRequest) {
      const loc = window.location.href;
      if (loc.indexOf('/datenschutz') !== -1 || loc.indexOf('/impressum') !== -1) {
        doConsentRequest = false;
      }
    }

    return doConsentRequest;
  }

  componentDidMount() {
    this.setState({
      sessionId: this.props.store$sessionId || uuidv4(),
      overlayActive: this.checkStatus() && !this.isCrawler()
    }, () => {
      this.props.dispatch$setSessionId(this.state.sessionId);
      if (this.isCrawler()) {
        Tracking.sendEvent('crawler', this.props.store$sessionId || this.state.sessionId);
        let consent = [];
        for (let i in ConsentData.sections) {
          consent.push(ConsentData.sections[i].id);
        }
        (new Promise((resolve) => {
          this.props.dispatch$setConsent(consent);
          resolve();
        })).then(() => {
          this.props.dispatch$setLastRequest((new Date()).getTime());
          this.pushConsent();
        });
      } else if (this.state.overlayActive) {
        Tracking.sendEvent('shown', this.props.store$sessionId || this.state.sessionId);
      } else {
        Tracking.sendEvent('not shown', this.props.store$sessionId || this.state.sessionId);
        this.pushConsent();
      }
    });
  }

  pushConsent(doClose) {
    Datalayer.push(this.props.store$consent);

    let requiredCount = 0;
    for (let i in ConsentData.sections) {
      if (ConsentData.sections[i].required) {
        requiredCount++;
      }
    }
    const consentStatus = (requiredCount === this.props.store$consent?.length ? 'consent-none' : (ConsentData.sections.length === this.props.store$consent?.length ? 'consent-full' : 'consent-partial'));
    Tracking.sendEvent(consentStatus, this.props.store$sessionId || this.state.sessionId);
    if (doClose === true) {
      this.setState({
        overlayActive: false
      });
    }
  }

  isCrawler() {
    var crawlerRegEx = /crawler|bot|spider|larbin|ABACHOBot|80legs|AddSugarSpiderBot|AnyApexBot|Baidu|B-l-i-t-z-B-O-T|BecomeBot|BillyBobBot|Bimbot|Arachmo|Accoona-AI-Agent|searchme\.com|Cerberian Drtrs|DataparkSearch|Covario-IDS|findlinks|holmes|htdig|ia_archiver|ichiro|igdeSpyder|L\.webis|LinkWalker|lwp-trivial|mabontland|Google|Mnogosearch|mogimogi|MVAClient|NetResearchServer|NewsGator|NG-Search|Nymesis|oegp|Pompos|PycURL|Qseero|SBIder|SBIder|ScoutJet|Scrubby|SearchSight|semanticdiscovery|silk|Snappy|Sqworm|StackRambler|Ask Jeeves\/Teoma|truwoGPS|voyager|VYU2|^updated|TinEye|webcollage|Yahoo|yoogliFetchAgent|^Zao/i;
    return crawlerRegEx.test(window.navigator.userAgent);
  }

  initGlobalFunction() {
    window.WebConsent.show = () => {
      this.setState({
        overlayActive: true,
        expanded: true
      });
      Tracking.sendEvent('shown-requested', this.props.store$sessionId);
    };

    window.WebConsent.clear = () => {
      this.props.dispatch$clearStore();
      window.location.reload();
    }
  }

  customizeCallback() {
    this.setState({
      expanded: true
    });
  }

  render() {
    if (this.state.overlayActive) {
      const ConsentDialog = loadable(() => import(/* webpackChunkName: "dialog" */ '../components/ConsentDialog.jsx'));
      return (
        <ConsentDialog consentConfig={this.ConsentConfig} expanded={this.state.expanded} consentCallback={this.pushConsent} customizeCallback={this.customizeCallback} />
      )
    } else {
      return '';
    }
  }
}

export default connect(mapStoreToProps, mapDispatchToProps)(ConsentView);
