import React from 'react';

import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

// Theme
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import theme from './theme/default.js';

// Store
import store from './store/store.js';
import { Provider as StoreProvider } from 'react-redux';

// Views
import ConsentView from './views/ConsentView.jsx';

const merge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) Object.assign(source[key], merge(target[key], source[key]))
  }
  Object.assign(target || {}, source)
  return target
};

class WebConsent extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch({
      type: 'ADD_CONFIG',
      payload: this.props.config
    });

    this.theme = createMuiTheme(merge(theme, window.WebConsent?.theme || {}));
  }

  render() {
    return (
      <React.Fragment>
        <StoreProvider store={store}>
          <ThemeProvider theme={this.theme}>
            <ScopedCssBaseline>
              <ConsentView mode="compact" />
            </ScopedCssBaseline>
          </ThemeProvider>
        </StoreProvider>
      </React.Fragment>
    );
  }
};

export default WebConsent;
