const consent = {
  sections: [
    {
      id: 'consent_technical',
      headline: 'Technisch notwendige Cookies',
      description: 'Diese Cookies sind für die Bereitstellung von Diensten, die über unsere Website verfügbar sind, und für die Verwendung bestimmter Funktionen unserer Website von wesentlicher Bedeutung.<br/> Ohne diese Cookies können wir Ihnen bestimmte Dienste auf unserer Website nicht zur Verfügung stellen.',
      required: true
    },
    // {
    //   id: 'consent_functional',
    //   headline: 'Funktions Cookies',
    //   description: 'Diese Cookies werden verwendet, um Ihnen ein persönlicheres Erlebnis auf unserer Website zu ermöglichen und um sich an Ihre Entscheidungen zu erinnern, die Sie bei der Nutzung unserer Website getroffen haben.<br/>Beispielsweise können wir Funktions-Cookies verwenden, um Ihre Spracheinstellungen oder Ihre Anmeldedaten zu speichern.'
    // },
    {
      id: 'consent_analytics',
      headline: 'Tracking und Performance Cookies',
      description: 'Diese Cookies werden zum Sammeln von Informationen verwendet, um den Verkehr auf unserer Website und die Nutzung unserer Website durch Besucher zu analysieren.<br/>Diese Cookies können beispielsweise nachverfolgen, wie lange Sie auf der Website verweilen oder welche Seiten Sie besuchen. So können wir verstehen, wie wir unsere Website für Sie verbessern können.<br/>Die durch diese Tracking- und Performance-Cookies gesammelten Informationen identifizieren keinen einzelnen Besucher.',
    },
    {
      id: 'consent_marketing',
      headline: 'Targeting und Werbung Cookies',
      description: 'Diese Cookies werden genutzt, um Werbung anzuzeigen, die Sie aufgrund Ihrer Surfgewohnheiten wahrscheinlich interessieren wird. Diese Cookies, die von unseren Inhalten und / oder Werbeanbietern bereitgestellt werden, können Informationen, die sie von unserer Website gesammelt haben, mit anderen Informationen kombinieren, welche sie durch Aktivitäten Ihres Webbrowsers in Ihrem Netzwerk von Websites gesammelt haben. Wenn Sie diese Targeting- oder Werbe-Cookies entfernen oder deaktivieren, werden weiterhin Anzeigen angezeigt. Diese sind für Sie jedoch möglicherweise nicht relevant.',
    },
  ]
};

export default consent;
