import ConsentData from '../config/consent.js';

const initGTM = () => {
  if (typeof window.google_tag_manager === 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js'
    });
    window.gtag = function() { window.dataLayer.push(arguments); }
    window.gtag(
      'consent',
      'default',
      {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'ad_personalization': 'denied',
        'ad_user_data': 'denied',
        'ads_data_redaction': 'denied',
      }
    );
    let s = window.document.createElement('script');
    s.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-W7TVFBR';
    s.async = true;
    window.document.body.appendChild(s);
  }
}

const push = (consent) => {
  let requiredCount = 0;
  for (let i in ConsentData.sections) {
    if (ConsentData.sections[i].required) {
      requiredCount++;
    }
  }
  if (requiredCount < consent.length) {
    initGTM();
    let consentPushed = false;
    for (let i in window.dataLayer) {
      if (window.dataLayer[i].event === 'consent') {
        consentPushed = true;
        break;
      }
    }
    let oldDatalayer = [];
    if (!consentPushed) {
      oldDatalayer = [...window.dataLayer];
    }
    for (let i in consent) {
      window.dataLayer.push({
        'event': 'consent',
        'consent': consent[i]
      });
    }
    if (oldDatalayer.length > 0) {
      for (let i in oldDatalayer) {
        window.dataLayer.push(oldDatalayer[i]);
      }
    }
    window.gtag(
      'consent',
      'update',
      {
        'ad_storage': consent.filter((item) => item === 'consent_marketing').length > 0 ? 'granted' : 'denied',
        'analytics_storage': consent.filter((item) => item === 'consent_analytics').length > 0 ? 'granted' : 'denied',
        'ad_personalization': consent.filter((item) => item === 'consent_marketing').length > 0 ? 'granted' : 'denied',
        'ad_user_data': consent.filter((item) => item === 'consent_marketing').length > 0 ? 'granted' : 'denied',
        'ads_data_redaction': consent.filter((item) => item === 'consent_marketing').length > 0 ? 'granted' : 'denied',
      }
    );
  }
};

export default {
  push
};
