import React from 'react';
import ReactDOM from 'react-dom';
import WebConsent from './WebConsent.jsx';

function app(config) {
  let anchor = document.createElement('div');
  anchor.id = 'app-web-consent';
  document.body.appendChild(anchor);
  ReactDOM.render(
    <WebConsent config={config} />,
    document.getElementById('app-web-consent')
  );
}

function init(config) {
  app(config);
}

export default init;
