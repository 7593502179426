import { createStore } from 'redux';
import { loadStateLocal, saveStateLocal } from './localStorage.js';
import { loadStateSession, saveStateSession } from './sessionStorage.js';
import throttle from 'lodash/throttle';

const initialStateLocal = {
  config: {},
  shown: false,
  consent: [],
  lastRequest: null
};
const initialStateSession = {
  s: null
};
let initialStateUpdated = {...initialStateLocal, ...initialStateSession};

const reducer = (state = initialStateUpdated, action) => {
  switch (action.type) {
    case 'ADD_CONFIG':
      state = {
        ...state,
        config: action.payload
      };
      break;
    case 'SET_SHOWN':
      state = {
        ...state,
        shown: action.payload
      };
      break;
    case 'SET_CONSENT':
      state = {
        ...state,
        consent: action.payload
      };
      break;
    case 'SET_LASTREQUEST':
      state = {
        ...state,
        lastRequest: action.payload
      };
      break;
    case 'SET_SESSIONID':
      state = {
        ...state,
        s: action.payload
      };
      break;
    case 'CLEAR_STORE':
      state = {
        ...state,
        ...{
          s: null,
          lastRequest: null,
          consent: []
        }
      };
      break;
    default:
      break;
  }
  return state;
}

const persistedStateLocal = loadStateLocal(initialStateLocal);
const persistedStateSession = loadStateSession(initialStateSession);
const persistedState = {...initialStateLocal, ...persistedStateLocal, ...persistedStateSession};
const store = createStore(
  reducer,
  persistedState,
  (
    (window.location.href.indexOf('local') > -1 || window.location.href.indexOf('test') > -1) ?
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) :
    undefined
  )
);

store.subscribe(throttle(() => {
  saveStateLocal(store.getState());
  saveStateSession(store.getState());
}, 1000));

export default store;
