const STORE_NAME = 'consent-store';

const saveKeys = [
  'consent',
  'lastRequest'
];

export const loadStateLocal = (initialState) => {
  try {
    const serializedState = window.localStorage.getItem(STORE_NAME);

    if (serializedState === null) {
      return undefined;
    }

    let parsed = JSON.parse(serializedState);
    return {...initialState, ...parsed};
  } catch (error) {
    return undefined;
  }
};

export const saveStateLocal = (state) => {
  try {
    let data = {...state};
    let keys = Object.keys(data);
    for (let i in keys) {
      if (!saveKeys.includes(keys[i])) {
        delete(data[keys[i]]);
      }
    }
    const serializedState = JSON.stringify(data);
    window.localStorage.setItem(STORE_NAME, serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
